@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', 'Open Sans', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  overflow-x: hidden;
  height: 100% !important;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scrollbox {
  max-height: 25rem;
  overflow: auto;
  visibility: hidden;
}

.scrollbox-content,
.scrollbox:hover,
.scrollbox:focus {
  visibility: visible;
}

.content-css {
  padding-right: 36px;
  padding-left: 48px;
}

.content-mobile-css {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: justify;
}

.content-css > span > span {
  word-break: break-all;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: medium;

}
@media (min-width: 450px) {
  .content-mobile-css   {
    display: none;

  }
}

input,
textarea {
  font-size: 16px !important;
}

.content-mobile-css > span > span {
  word-break: break-all;
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 14px;
  text-align: justify;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 12px;
}

@media (max-width: 700px) {
  body {
    overflow: hidden !important;
    overflow-x: hidden !important;
    height: 100% !important;
    overflow-y: scroll !important;
  }
  ._mobileView {
    display: block !important;
  }

  ._desktopView {
    display: none !important;
  }
}

@media (min-width: 700px) {
  ._mobileView {
    display: none !important;
  }

  ._desktopView {
    display: block !important;
  }
}

.slick-track {
  //width: auto !important;
}

